<div class="ion-margin">
	<ion-input
		class="ion-margin-bottom"
		fill="outline"
		placeholder="{{ 'ENTER_CATEGORY_NAME' | translate }}"
		size="small"
		mode="md"
		[(ngModel)]="groupName"
	></ion-input>

	<ion-textarea
		class="ion-margin-bottom"
		fill="outline"
		placeholder="{{ 'ENTER_CATEGORY_DESCRIPTION' | translate }}"
		size="small"
		mode="md"
		[(ngModel)]="description"
		autoGrow="true"
		style="max-width: 100%"
	></ion-textarea>

	<ion-button mode="ios" expand="block" [disabled]="!groupName?.length || groupName === undefined" (click)="onSaveGroup()">
		{{ 'SAVE' | translate }}
	</ion-button>
</div>
