import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISubCategoriesWithServicesDto } from '@solar/core/src/services/signals/sub-categories-state/sub-categories-state.service';
import { Observable } from 'rxjs';
import { ISubCategoriesDto, ISubCategoriesModel } from './sub-categories.dto';
@Injectable({
	providedIn: 'root',
})
export class SubCategoriesService {
	constructor(private readonly http: HttpClient) {}

	public getAllSubCategories(reqData: { parent_id?: number }): Observable<ISubCategoriesDto[]> {
		let params = new HttpParams();
		params = params.set('parent_id', reqData?.parent_id);

		return this.http.get<ISubCategoriesDto[]>(`sub-categories`, { params });
	}

	public editSubCategory(data: ISubCategoriesDto): Observable<ISubCategoriesDto> {
		const formData = new FormData();
		formData.append('title', data.title);
		formData.append('description', data.description);
		formData.append('parent_id', String(data.parent_id));

		// if (data?.img) {
		// 	formData.append('img', data.img, data.img.name);
		// } else {
		// 	formData.append('img', '');
		// }

		return this.http.patch<ISubCategoriesDto>(`sub-categories/${data.id}`, formData);
	}

	public createSubCategory(data: ISubCategoriesModel): Observable<ISubCategoriesDto> {
		return this.http.post<ISubCategoriesDto>(`sub-categories`, this.setFormData(data));
	}

	public changePositions(data: ISubCategoriesWithServicesDto[]): Observable<ISubCategoriesWithServicesDto[]> {
		return this.http.post<ISubCategoriesWithServicesDto[]>(`sub-categories/positions`, data);
	}

	public deleteSubCategory(data: { id: number }): Observable<number> {
		return this.http.delete<number>(`sub-categories/${data.id}`);
	}

	private setFormData(data: ISubCategoriesModel): FormData {
		const formData = new FormData();
		formData.append('title', data.title);
		formData.append('description', data.description);
		formData.append('parent_id', String(data.parent_id));

		if (data?.img) {
			formData.append('img', data.img, data.img.name);
		} else {
			formData.append('img', '');
		}

		return formData;
	}
}
