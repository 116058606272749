<ion-header mode="ios">
	<hb-header backUrl="/" title="REVIEWS"></hb-header>
</ion-header>

<ion-content>
	<hb-reviews-modal *isAuthorized [userId]="userId"></hb-reviews-modal>

	<ng-container *isAuthorized="false">
		<div class="ion-padding">
			<ion-text color="primary" (click)="onRouteToLogin()">
				{{ 'REVIEW_CONDITIONS_MSG' | translate }}
			</ion-text>
		</div>
	</ng-container>

	@if (reviews$ | async; as reviews) {
		<div>
			@if (reviews?.data?.length) {
				<ion-list mode="ios" lines="inset" class="ion-padding-top">
					@for (it of reviews.data; track it) {
						<ion-item class="ion-margin-bottom">
							<div class="ion-padding-bottom">
								<div class="flex ion-align-items-center">
									<hb-avatar size="small" class="m-r-12" [data]="it"></hb-avatar>
									<div class="font-weight-500 font-size-14">{{ it?.user_name ? it.user_name : (it | fullName) }}</div>
								</div>
								<div class="flex ion-align-items-center">
									<hb-stars class="m-b-6 m-t-6 sr-block" [average_rating]="it.rating"></hb-stars>
									<div class="font-size-13 m-l-4">
										<ion-text color="medium">{{ it.created_at | dateAgo }}</ion-text>
									</div>
								</div>
								<div class="w100" class="white-space-break font-size-14">{{ it.message }}</div>
							</div>
						</ion-item>
					}
				</ion-list>
			}
			@if (!reviews?.data?.length) {
				<div class="flex ion-justify-content-center ion-margin-horizontal">
					<ion-card class="w100">
						<ion-card-header>
							<div class="ion-text-center">
								<ion-icon name="file-tray-stacked-outline"></ion-icon>
							</div>
						</ion-card-header>
						<ion-card-content>
							<ion-text color="medium">
								<div class="ion-text-center">
									{{ 'LIST_EMPTY' | translate }}
								</div>
							</ion-text>
						</ion-card-content>
					</ion-card>
				</div>
			}
		</div>
	}
</ion-content>
