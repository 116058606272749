import { ChangeDetectionStrategy, ChangeDetectorRef, Component, InputSignal, OnInit, inject, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CategoriesRes } from '@solar/core/src/store';
import { SubCategoriesStateService } from '../../../../../../libs/core/src/services/signals/sub-categories-state/sub-categories-state.service';
import { ISubCategoriesDto } from './../../../../../../libs/core/src/services/rest/sub-categories/sub-categories.dto';

@Component({
	selector: 'hb-sub-categories-form',
	templateUrl: './sub-categories-form.component.html',
	styleUrls: ['./sub-categories-form.component.scss'],
	imports: [IonicModule, FormsModule, TranslateModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubCategoriesFormComponent implements OnInit {
	private readonly ch = inject(ChangeDetectorRef);
	private readonly modalCtrl = inject(ModalController);

	private readonly subCategoriesService = inject(SubCategoriesStateService);

	public category: InputSignal<CategoriesRes> = input();

	public editData: InputSignal<ISubCategoriesDto> = input();

	public groupName: string;
	public description: string;

	public ngOnInit(): void {
		if (this.editData()) {
			this.groupName = this.editData().title;
			this.description = this.editData().description;
			this.ch.detectChanges();
		}
	}

	public async onSaveGroup(): Promise<void> {
		if (this.groupName) {
			if (this.editData()) {
				await this.subCategoriesService.edit({
					...this.editData(),
					title: this.groupName,
					description: this.description,
				});
			} else {
				await this.subCategoriesService.create({
					title: this.groupName,
					description: this.description,
					parent_id: this.category().id,
				});
			}

			this.onClear();
		}
	}

	public onClear(): void {
		if (this.editData()) {
			void this.modalCtrl.dismiss(
				{
					...this.editData(),
					title: this.groupName,
					description: this.description,
				},
				'confirm',
			);
		} else {
			void this.modalCtrl.dismiss();
		}

		this.groupName = '';
		this.description = '';

		this.ch.detectChanges();
	}
}
