import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDayClose } from './close-day.dto';

@Injectable({
	providedIn: 'root',
})
export class CloseDayService {
	constructor(private readonly http: HttpClient) {}

	public closeDay(body: IDayClose): Observable<[]> {
		return this.http.post<[]>(`/close-day`, body);
	}
}
