import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { CategoryServicesService } from '@solar/core/src';
import { CategoryServicesRes } from '@solar/core/src/store';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ItemsInCategoryStateService {
	private readonly restService = inject(CategoryServicesService);

	public items: WritableSignal<CategoryServicesRes[]> = signal(null);

	constructor() {}

	public async fetchData({ category_id }: { category_id: number }): Promise<void> {
		const res = await lastValueFrom(this.restService.getAllInCategory({ category_id }));

		this.items.set(res.data);
	}

	// public async create(data: ISubCategoriesModel): Promise<void> {
	// 	const res: ISubCategoriesDto = await lastValueFrom(this.subCategoriesService.createSubCategory(data));

	// 	this.items.update(items => [...items, res]);
	// }

	// public async edit(data: ISubCategoriesDto): Promise<void> {
	// 	const res: ISubCategoriesDto = await lastValueFrom(this.subCategoriesService.editSubCategory(data));

	// 	if (this.items.length) {
	// 		this.items.update(items => items.map(item => (item.id === res.id ? res : item)));
	// 		return;
	// 	}

	// 	await this.fetchData({ parent_id: res.parent_id });
	// }

	// public async delete({ id }): Promise<void> {
	// 	await lastValueFrom(this.subCategoriesService.deleteSubCategory({ id }));

	// 	this.items.update(items => items.filter(item => item.id !== id));
	// }
}
