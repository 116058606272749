import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, input, signal } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { PrintersService } from '@solar/core/src/services/rest/printers/printers.service';
import { ToastService } from '@solar/core/src/services/toast/toast.service';

export interface IReceiptData {
	id: number;
	title: string;
	qty: number;
	price: number;
	currency: string;
	date: string;
	customer_id?: number;
	master_id?: number;
}

@Component({
	selector: 'hb-print-receipt-btn',
	templateUrl: './print-receipt-btn.component.html',
	styleUrls: ['./print-receipt-btn.component.scss'],
	providers: [IonicModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PrintReceiptBtnComponent {
	public item = input<IReceiptData>();

	protected isPending = signal<boolean>(false);

	constructor(
		private readonly printersService: PrintersService,
		private readonly toastService: ToastService,
		protected readonly platform: Platform,
	) {}

	public async onPrint(): Promise<void> {
		this.isPending.set(true);

		const it: IReceiptData = {
			id: this.item().id,
			title: this.item().title,
			qty: 1,
			price: +this.item().price,
			currency: this.item().currency,
			date: this.item().date,
			customer_id: this.item().customer_id,
			master_id: this.item().master_id,
		};

		try {
			await this.printersService.printTheBill([it]);
		} catch (error) {
			void this.toastService.presentToast(error.message);
		}

		this.isPending.set(false);
	}
}
