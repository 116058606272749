import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IChat } from '@solar/core/src';
import { Observable, lastValueFrom } from 'rxjs';
import { IChatMembers } from './../../../interfaces/chats/chat-members.interface';
import { IPagination } from './../../../interfaces/pagination.interface';

@Injectable({
	providedIn: 'root',
})
export class ChatsService {
	constructor(private readonly http: HttpClient) {}

	public getAll(reqData: { page?: number; is_group?: boolean }): Observable<IPagination<IChat>> {
		let params = new HttpParams();
		params = params.set('page', reqData?.page ? reqData.page.toString() : '1');

		if (reqData?.is_group !== undefined) {
			params = params.set('is_group', reqData?.is_group ? 'true' : 'false');
		}

		return this.http.get<IPagination<IChat>>(`chats`, { params });
	}

	public createChat(data: { user_1_id: number; user_2_id: number; is_group: boolean; chat_id: string }): Observable<string> {
		return this.http.post<string>(`chats`, data);
	}

	public async createOrGetChatIdAsync(data: {
		user_1_id: number;
		user_2_id: number;
		is_group: boolean;
		chat_id: string;
	}): Promise<{ data: { chat_id: string } }> {
		try {
			return await lastValueFrom(this.http.post<{ data: { chat_id: string } }>(`chats`, data));
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public editChat(data: { chat_name: string; chat_id: string; img?: File; description?: string }): Observable<IChat> {
		const formData = new FormData();
		formData.append('chat_id', data.chat_id);
		formData.append('chat_name', data.chat_name);
		formData.append('description', data.description);

		if (data?.img) {
			formData.append('img', data.img, data.img.name);
		} else {
			formData.append('img', '');
		}

		return this.http.patch<IChat>(`chats`, formData);
	}

	public createGroupChat(data: { chat_name: string; members: number[]; img: File }): Observable<string> {
		const formData = new FormData();
		formData.append('chat_name', data.chat_name);
		formData.append('members', JSON.stringify(data.members));

		if (data?.img) {
			formData.append('img', data.img, data.img.name);
		} else {
			formData.append('img', '');
		}

		return this.http.post<string>(`chats/group-chat`, formData);
	}

	public deleteChat(data: { chat_id: number; user_id?: number }): Observable<string> {
		let params = new HttpParams();
		params = params.set('chat_id', data.chat_id.toString());

		if (data.user_id) {
			params = params.set('user_id', data.user_id.toString());
		}

		return this.http.delete<string>(`chats`, { params });
	}

	public async leaveChat(data: { chat_id: string; user_id?: number }): Promise<string> {
		try {
			return await lastValueFrom(this.http.post<string>('chats/leave', data));
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public async addUserToChat(data: { chat_id: string; members?: number[] }): Promise<IChatMembers> {
		try {
			return await lastValueFrom(this.http.post<IChatMembers>('chats/add-user', data));
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public async getChat(data: { chat_id: string; user_id?: number }): Promise<IChat> {
		try {
			let params = new HttpParams();

			if (data.user_id) {
				params = params.set('user_id', data.user_id.toString());
			}

			return await lastValueFrom(this.http.get<IChat>(`chats/details/${data.chat_id}`, { params }));
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public async getChatMembers(data: { chat_id: string }): Promise<IPagination<IChatMembers>> {
		try {
			return await lastValueFrom(this.http.get<IPagination<IChatMembers>>(`chats/members?chat_id=${data.chat_id}`));
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public async getChatMember(data: { chat_id: string }): Promise<IChatMembers> {
		try {
			return await lastValueFrom(this.http.get<IChatMembers>(`chats/member?chat_id=${data.chat_id}`));
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
