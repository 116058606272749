<ion-header mode="ios">
	<hb-header title="{{ (category ? 'EDIT' : 'ADD') | translate }} {{ model | uppercase | translate }}">
		<ion-button slot="end" (click)="onClose()" fill="clear" color="medium">
			<ion-icon slot="icon-only" name="close-outline"></ion-icon>
		</ion-button>
	</hb-header>
</ion-header>

<ion-content>
	<ion-list mode="ios" [inset]="true">
		<form [formConnect]="formName" [formGroup]="form" class="login-form" (onSuccessForm)="onSuccessForm($event)">
			@if (userRoleService.isAdmin) {
				<ion-item>
					<ion-input fill="outline" [label]="'Position on the list' | translate" formControlName="position" type="number"></ion-input>
				</ion-item>
			}

			<ion-item>
				<ion-input fill="outline" [label]="'Enter title' | translate" formControlName="title"></ion-input>
			</ion-item>

			<ion-item>
				<ion-input fill="outline" [label]="'Enter title (ru)' | translate" formControlName="title_ru"></ion-input>
			</ion-item>

			<ion-item tappable="true" button (click)="onOpenManagerModal()">
				<div class="flex ion-align-items-center">
					<div class="ion-margin-end">
						{{ 'MANAGERS' | translate }}
					</div>
					<div class="flex ion-align-items-center ion-align-items-end">
						@if (managers()?.length) {
							({{ managers().length }})
						} @else {
							{{ 'Select manager' | translate }}
						}
					</div>
				</div>
			</ion-item>

			<hb-user-search [isOpen]="isOpenManagerModal" (dismiss)="onDismiss($event)"></hb-user-search>

			<ion-item button class="file-upload-wrapper" detail-icon="image-outline">
				<ion-label>{{ imagePreviewUrl ? '' : ('SELECT_IMAGE' | translate) }}</ion-label>
				<input (change)="onSelectFile($event)" name="file-upload-field" type="file" class="file-upload-field" value="" />
			</ion-item>

			<div class="ion-margin-horizontal">
				<img style="max-width: 100%; border-radius: 9px" [src]="imagePreviewUrl" alt="" />
			</div>

			<ion-item class="m-b-28">
				<ion-toggle formControlName="is_active" [enableOnOffLabels]="true">
					<ion-text [color]="form.controls.is_active.value ? 'success' : 'warning'">
						{{ (form.controls.is_active.value ? 'ON_LINE' : 'OFF_LINE') | translate }}
					</ion-text>
				</ion-toggle>
			</ion-item>

			<div class="ion-margin-horizontal ion-margin-bottom">
				<hb-submit [form]="form" [formName]="formName" [configs]="{ label: category ? 'EDIT' : 'ADD' }"></hb-submit>
			</div>
		</form>
	</ion-list>
</ion-content>
