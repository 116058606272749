<ion-modal [breakpoints]="[0, 1]" [initialBreakpoint]="1" [canDismiss]="true" mode="ios" [isOpen]="isOpen" (willDismiss)="onDismiss()">
	<ng-template>
		<hb-header>
			<ion-toolbar>
				<ion-title>{{ 'SELECT_MANAGERS' | translate }}</ion-title>
				<ion-buttons slot="end">
					<ion-button (click)="confirm()">
						{{ 'SAVE' | translate }}
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
			<ion-searchbar
				[placeholder]="'SEARCH' | translate"
				class="ion-margin-top"
				mode="ios"
				[debounce]="1000"
				(ionInput)="handleInput($event)"
			></ion-searchbar>
		</hb-header>
		<ion-content>
			<ion-list [inset]="true" mode="ios">
				<ion-list-header>
					{{ 'SELECTED_MANAGERS' | translate }}
				</ion-list-header>
				@if (checked().length) {
					@for (result of checked(); track $index) {
						<ion-item>
							<ion-checkbox
								[disabled]="checked().length === 1"
								[checked]="checked()"
								(ionChange)="onChange($event, result)"
								justify="space-between"
							>
								<div class="flex ion-align-items-center">
									<hb-avatar
										class="ion-margin-end"
										[data]="{ user_name: result.user_name, img: result.img, id: result.id }"
										size="small"
									></hb-avatar>
									<ion-label>{{ result.user_name ? result.user_name : (result | fullName) }}</ion-label>
								</div>
							</ion-checkbox>
						</ion-item>
					}
				} @else {
					<div class="flex h100 ion-justify-content-center ion-padding">
						{{ 'NO_MANAGERS' | translate }}
					</div>
				}
			</ion-list>

			@if (searchResults()?.length) {
				<ion-list [inset]="true" mode="ios">
					<ion-list-header>
						{{ 'GENERAL_SEARCH_LIST' | translate }}
					</ion-list-header>
					@for (result of searchResults(); track $index) {
						<ion-item button detail="false" (click)="onChange($event, result)">
							<ion-avatar slot="start" size="large">
								<hb-avatar [data]="{ user_name: result.user_name, img: result.img, id: result.id }" size="small"></hb-avatar>
							</ion-avatar>
							<ion-label>{{ result.user_name ? result.user_name : (result | fullName) }}</ion-label>
							<ion-button slot="end">
								<ion-icon name="add-outline"></ion-icon>
							</ion-button>
						</ion-item>
					}
				</ion-list>
			}
		</ion-content>
	</ng-template>
</ion-modal>
