<ion-item mode="ios" class="hb-order-item" color="light">
	<ion-text color="medium" class="font-size-10 id-box">id: {{ it.id }}</ion-text>
	<ion-label class="ion-text-wrap ion-margin-vertical ion-no-margin">
		<div *showOnlyFor="[UserRolesEnum.MASTER, UserRolesEnum.ADMIN]" color="light" class="font-size-14">{{ 'CLIENT' | translate }}:</div>
		<ion-item
			*showOnlyFor="[UserRolesEnum.MASTER, UserRolesEnum.ADMIN]"
			mode="md"
			class="font-size-16 m-b-12 flex-row ion-justify-content-between font-weight-500 bg-light-shade"
			style="border-radius: 8px"
		>
			<div>
				<div class="m-t-10">
					@if (it?.user_id && it?.user_id !== authService?.principal?.id) {
						<div class="flex" [routerLink]="'/card/' + it.client_id + '/master'">
							<hb-avatar
								size="small"
								slot="start"
								[data]="{ id: it.client_id, img: it.client_img, user_name: it.client_user_name }"
							></hb-avatar>

							<div class="flex ion-align-items-center m-l-10">
								{{ it.client_user_name }}
							</div>
						</div>
					} @else {
						<div (click)="writeToClipboard(it.name)" class="flex ion-align-items-center">
							{{ it.name }}
						</div>
					}
				</div>
				@if (it?.total_orders) {
					<ion-buttons class="ion-no-margin btn" mode="ios" (click)="onSearch(it.phone)">
						<ion-button class="btn-inner">
							<ion-text color="medium">{{ 'NUMBER_OF_ORDERS' | translate }}:</ion-text>&nbsp;
							<ion-text color="primary">{{ it.total_orders }}</ion-text>
						</ion-button>
					</ion-buttons>
				}
			</div>
			<div slot="end" (click)="writeToClipboard(it.phone)">{{ it.phone }}</div>
		</ion-item>
		@if (it.wishes) {
			<div class="font-size-14 m-b-8 m-t-8">{{ 'WISHES' | translate }}: {{ it.wishes }}</div>
		}

		<div class="font-size-14 m-b-8">
			<hb-edited-date [order]="it"></hb-edited-date>
			{{ it.date | date: 'mediumDate' : 'UTC' }} , {{ it.hours && sumServicesTimes(it.hours) }}
		</div>
		<div class="font-size-14 m-b-8">
			<div class="m-b-8">
				@if (it?.order_items?.length > 1) {
					<div mode="ios" class="ion-no-margin">
						@for (o of it.order_items; track $index) {
							<div class="bg-light-shade">
								<ion-label class="font-size-14">
									{{ o.title }}
									@if (o.price && +o.price > 0) {
										:&nbsp;<ion-note color="dark">
											<span class="font-size-14"
												><span class="font-weight-500">{{ o.price | localizedCurrency: o?.currency : 'symbol' : o.price_to }}</span></span
											>
										</ion-note>
									}
								</ion-label>
							</div>
						}
						<div class="m-t-10">
							<ion-note color="dark" class="font-size-14">
								{{ 'TOTAL' | translate }}
								<span
									>: <span class="font-weight-500">{{ it.price | localizedCurrency: it?.currency : 'symbol' : it.price_to }}</span></span
								>
							</ion-note>
						</div>
					</div>
				} @else {
					{{ it | serverTranslate: 'title' }}
					@if (it.price && +it.price > 0) {
						<span class="font-size-14"
							>: <span class="font-weight-500">{{ it.price | localizedCurrency: it?.currency : 'symbol' : it.price_to }}</span></span
						>
					}
				}
			</div>

			@if (it.rent_time) {
				<div>{{ ('DATE_RENT' | translate) + ': ' + it.rent_time + ' ' + ('HOURS' | translate) }}</div>
			}
		</div>

		<div class="flex-row">
			@if (it.master) {
				<div class="font-size-14 flex-row ion-align-items-center" [routerLink]="'/card/' + it.master.id + '/master'">
					<hb-avatar size="small" slot="start" [data]="it.master"></hb-avatar>
					<div class="m-l-10">
						{{ it.master | serverTranslate: 'first_name' }}
					</div>
				</div>
			}

			<div class="flex ion-align-items-center ion-justify-content-center">
				<ion-chip [color]="statusColor(it.order_status_id)" class="ion-no-margin white-space-no-wrap" mode="ios">
					{{ statuses[it.order_status_id] | translate }}
				</ion-chip>

				<ion-buttons (click)="menuEvent.emit(it)" class="m-l-8" color="pink" mode="ios">
					<ion-button>
						<ion-icon slot="icon-only" icon="ellipsis-horizontal"></ion-icon>
					</ion-button>
				</ion-buttons>
			</div>
		</div>
	</ion-label>
</ion-item>
