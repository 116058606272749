import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	computed,
	effect,
	EventEmitter,
	Input,
	Output,
	Signal,
	signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UserSearchStateService } from '@solar/core/src';
import { UserService } from '@solar/core/src/services/rest/user';
import { UserRes } from '@solar/core/src/store';
import { AvatarComponent, FullNamePipe, HeaderComponent } from '@solar/shared';
import { MastersService } from '../../../../../../src/app/core/services';

@Component({
	selector: 'hb-user-search',
	templateUrl: './user-search.component.html',
	styleUrls: ['./user-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonicModule, ReactiveFormsModule, TranslateModule, FormsModule, AvatarComponent, FullNamePipe, HeaderComponent],
})
export class UserSearchComponent {
	public searchResults = signal<UserRes[]>([]);

	@Output() public selection = new EventEmitter<UserRes[]>();
	@Output() public dismiss = new EventEmitter();

	@Input() public isOpen: boolean;
	public users: number[];

	protected checked = signal<UserRes[]>([]);

	constructor(
		private readonly userService: UserService,
		private readonly ch: ChangeDetectorRef,
		private readonly mastersService: MastersService,
		private readonly userSearchStateService: UserSearchStateService,
	) {
		effect(() => {
			this.users = this.userSearchStateService.items();

			if (this.users?.length) {
				void this.mastersService.getAllMasters({ ids: this.users.join(','), role: null }).then((res: any): void => {
					if (res?.data) {
						res.data.map(u => {
							this.onChange({ detail: { checked: true } }, u);
						});
					}
				});
			}
		});
	}

	public confirm(): void {
		this.onDismiss();
	}

	public handleInput(event): void {
		void this.userService.searchUsersAsync(event.target.value).then(res => {
			if (res) {
				this.searchResults.set(res);
			} else {
				this.searchResults.set([]);
			}
			this.ch.markForCheck();
		});
	}

	protected onChange(ev, item: UserRes): void {
		const { checked } = ev.detail;
		if (!checked && this.checked().includes(item)) {
			this.checked.update(users => users.filter(u => u.id !== item.id));
			this.searchResults.update(users => [item, ...users]);
		} else {
			this.checked.update(users => [item, ...users]);
			this.searchResults.update(users => users.filter(u => u.id !== item.id));
		}

		this.ch.detectChanges();
	}

	protected isChecked(item: UserRes): Signal<boolean> {
		return computed(() => this.checked().includes(item));
	}

	public onDismiss(): void {
		if (this.isOpen) {
			this.isOpen = false;
			void this.dismiss.emit(this.checked());
			this.userSearchStateService.items.set([]);
			this.searchResults.set([]);
		}
	}
}
