import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IMessage } from '../../interfaces';
import { MessagesService, MessagesWsService } from '../../services';
import { FormEffects, PagesEffects } from '../../store';
import { generateUID } from '../../utils';
import { AuthService } from './../../auth/auth.service';

export const addMessage = 'addMessage';
export const deleteMessage = 'deleteMessage';
export const deleteAllMessages = 'deleteAllMessages';

@Injectable()
export class MessagesEffects {
	constructor(
		private readonly formEffects: FormEffects,
		private readonly pagesEffects: PagesEffects,
		private readonly messagesService: MessagesService,
		private readonly messagesWsService: MessagesWsService,
		private readonly authService: AuthService,
	) {}

	public readonly $addMessage = this.formEffects.getSendFormEffect(addMessage, formState => {
		const uuid = generateUID();
		const addItem = {
			...formState.formData,
			id: null,
			uuid,
			receiver: +formState?.formData?.receiver,
			sender: +this.authService.principal.id,
			created_at: new Date().toISOString(),
			user_info: {
				id: this.authService.principal.id,
				img: this.authService.principal.img,
				first_name: this.authService.principal.first_name,
				last_name: this.authService.principal.last_name,
				user_name: this.authService.principal.user_name,
			},
		};

		if (!addItem?.files?.length) {
			this.messagesWsService.send(addItem);
		}

		return this.messagesService.sendMessage(formState.formData).pipe(
			map((it: IMessage) => {
				this.messagesWsService.send({ ...it, uuid });
				return it;
			}),
		);
	});

	public readonly $deleteMessage = this.formEffects.getSendFormEffect(deleteMessage, formState => {
		return this.messagesService.deleteItem(formState?.formData?.id).pipe(
			map(() => {
				this.messagesWsService.deleteMessage(formState?.formData?.id);
			}),
		);
	});

	public readonly $deleteAllMessages = this.formEffects.getSendFormEffect(deleteAllMessages, formState =>
		this.messagesService.deleteAllMessages(formState?.formData?.user_id).pipe(
			map(() => {
				this.messagesWsService.deleteMessage(formState?.formData?.user_id);
			}),
		),
	);
}
