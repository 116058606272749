<div #messagesContainer class="messages-container no-scroll">
	@if (messagesStateService.messages()?.data?.length && !isPending()) {
		@if (messagesStateService.messages()?.data?.length > messagesStateService.messages()?.size - 1) {
			<ion-infinite-scroll
				threshold="230px"
				position="top"
				(ionInfinite)="onIonInfinite($event, messagesStateService.messages().page, messagesStateService.messages().pages)"
			>
				<ion-infinite-scroll-content loadingSpinner="lines" loadingText=""></ion-infinite-scroll-content>
			</ion-infinite-scroll>
		}
		<div class="m-t-10 m-b-18">
			@for (it of messagesStateService.messages()?.data; track $index; let i = $index; let last = $last) {
				<hb-chat-modal-list-item
					(openFile)="setOpenFile($event, i)"
					(onImageLoad)="onImageLoad()"
					(openEmojis)="showEmojisModal($event)"
					(openActions)="onOpenActions($event)"
					[item]="it"
					[categoryId]="categoryId"
				></hb-chat-modal-list-item>
				@if (last && isUserAtBottom) {
					{{ onFirstLoadScroll() }}
				}
			}
		</div>
	} @else if (!isPending()) {
		<ion-card>
			<ion-card-content>
				<ion-text color="medium">
					<div class="ion-text-center">
						{{ 'LIST_EMPTY' | translate }}
					</div>
				</ion-text>
			</ion-card-content>
		</ion-card>
	} @else {
		<div class="flex ion-justify-content-center ion-padding">
			<ion-spinner color="pink" name="lines"></ion-spinner>
		</div>
	}
</div>

<ion-modal
	#modal
	[enterAnimation]="enterAnimation"
	[leaveAnimation]="leaveAnimation"
	mode="ios"
	[isOpen]="isModalOpen"
	(ionModalDidDismiss)="setOpenFile({ isOpen: false })"
	[keepContentsMounted]="true"
>
	<ng-template>
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="end">
					<ion-button (click)="modal.dismiss()">
						<ion-icon slot="icon-only" name="close-outline"></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<img src="{{ selectedImage }}" alt="" />

			@if (message && message !== 'null') {
				<div class="ion-padding">{{ message }}</div>
			}
		</ion-content>
		<!-- <hb-gallery-slides
				[slides]="(slides$ | async)?.data"
				[selectedIndex]="selectedIndex"
				(setOpenEvent)="setOpen($event)"
			></hb-gallery-slides> -->
		<ion-footer>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button slot="start" (click)="onDownload()">
						<ion-icon slot="icon-only" name="download-outline"></ion-icon>
					</ion-button>
				</ion-buttons>

				<ion-buttons slot="end">
					<ion-button (click)="onDelete(this.selectedImageId)">
						<ion-icon slot="icon-only" name="trash-outline"></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-footer>
	</ng-template>
</ion-modal>

<ion-modal
	mode="ios"
	[breakpoints]="[0, 0.5, 1]"
	[initialBreakpoint]="0.5"
	[isOpen]="isEmojisOpen"
	(ionModalDidDismiss)="showEmojisModal({ isOpen: false, message: null })"
>
	<ng-template>
		<hb-emojis-modal [message]="selectedMessage" (modalClosed)="showEmojisModal({ isOpen: false, message: null })"></hb-emojis-modal>
	</ng-template>
</ion-modal>

<!-- // for actions -->
<ion-popover
	[showBackdrop]="false"
	mode="ios"
	[dismissOnSelect]="false"
	[isOpen]="isActionsOpen"
	(didDismiss)="isActionsOpen = false"
	class="actions-modal"
>
	<ng-template>
		<div class="flex-row ion-justify-content-between p-l-10 p-r-10 p-t-10">
			<div class="flex">
				@for (item of shortEmojiList; track $index) {
					<ion-button
						slot="icon-only"
						class="ion-no-margin"
						size="small"
						fill="clear"
						[class.selected]="selectedIndex === $index"
						(click)="setSelectedEmoji(item.value)"
					>
						<div class="font-size-22">
							{{ item.value }}
						</div>
					</ion-button>
				}
			</div>

			<ion-button
				size="medium"
				color="medium"
				class="more-emojis"
				shape="round"
				(click)="showEmojisModal({ isOpen: true, message: selectedMessage })"
			>
				<ion-icon color="dark" slot="icon-only" name="chevron-down-outline"></ion-icon>
			</ion-button>
		</div>

		<hb-chat-modal-list-item
			class="m-t-10"
			[showActions]="false"
			[item]="selectedMessage"
			[categoryId]="categoryId"
		></hb-chat-modal-list-item>

		<ion-list inset="true" class="m-t-10 ion-no-margin">
			<ion-item class="font-size-14" color="light" [button]="true" [detail]="false" (click)="onReply()">
				<ion-icon aria-hidden="true" slot="start" name="arrow-redo-outline"></ion-icon>{{ 'REPLY' | translate }}
			</ion-item>

			<ion-item class="font-size-14" color="light" [button]="true" [detail]="false" (click)="writeToClipboard(selectedMessage.message)">
				<ion-icon aria-hidden="true" slot="start" name="copy-outline"></ion-icon>{{ 'COPY' | translate }}
			</ion-item>

			@if (
				selectedMessage?.user_info?.id === this.authService?.principal?.id ||
				(selectedMessage?.user_info?.id !== this.authService?.principal?.id && !messagesWsService?.is_group()) ||
				(chatMembersState?.authorizedMember()?.role === 'admin' && messagesWsService?.is_group())
			) {
				<ion-item class="font-size-14" color="light" [button]="true" [detail]="false" (click)="onDelete()">
					<ion-icon aria-hidden="true" slot="start" aria-hidden="true" slot="start" color="danger" name="trash-outline"></ion-icon
					><ion-text color="danger">{{ 'DELETE' | translate }}</ion-text>
				</ion-item>
			}
		</ion-list>
	</ng-template>
</ion-popover>
