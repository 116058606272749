import { Inject, Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { CapacitorHttp } from '@capacitor/core';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { LoadingController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ENV, IEnv } from '../../';
import { AlertService } from '../alert';

export const openSite = async (url: string): Promise<void> => {
	await Browser.open({ url });
};

const isNewBundleAvailable = async (latestBundleId: string): Promise<boolean> => {
	if (latestBundleId) {
		const { bundleId: currentBundleId } = await LiveUpdate.getBundle();
		return latestBundleId !== currentBundleId;
	} else {
		return false;
	}
};

@Injectable({
	providedIn: 'root',
})
export class UpdateForceService {
	public versionsData?: { android: string; ios: string; force: boolean };

	constructor(
		private readonly plt: Platform,
		private readonly alertService: AlertService,
		private readonly translate: TranslateService,
		@Inject(ENV) private readonly env: IEnv,
		private readonly loadingController: LoadingController,
	) {}

	public checkForUpdate(): void {
		if (this.plt.is('capacitor')) {
			const options = {
				url: `${this.env.api}/uploads/files/appVersions.json`,
			};

			void CapacitorHttp.get(options).then(async res => {
				if (this.env.production && res?.data) {
					this.versionsData = res.data;

					await this.checkConditions(this.plt.is('ios') ? this.versionsData?.ios : this.versionsData?.android);

					await LiveUpdate.ready();
					const availableNew = await isNewBundleAvailable(res.data.bundleId);
					if (availableNew) {
						await this.showLoadingSpinner();

						const { bundleId: currentBundleId } = await LiveUpdate.getBundle();

						if (currentBundleId) {
							await LiveUpdate.deleteBundle({ bundleId: currentBundleId });
						}

						await this.selfHosted(res);

						await this.hideLoadingSpinner();
					}
				}
			});
		}
	}

	private async showLoadingSpinner(): Promise<void> {
		const loading = await this.loadingController.create({
			message: this.translate.instant('UPDATING_MESSAGE'),
			spinner: 'crescent',
			duration: 28000,
		});

		await loading.present();
	}

	private async hideLoadingSpinner(): Promise<void> {
		try {
			await this.loadingController.dismiss();
		} catch (e) {
			console.warn('Error dismissing loading spinner', e);
		}
	}

	private async selfHosted(response): Promise<void> {
		try {
			await this.downloadBundle(response?.data?.url, response?.data?.bundleId);

			await this.setBundle(response?.data?.bundleId);
		} catch (e) {
			console.log(e);
			await this.hideLoadingSpinner();
		}
	}

	private async downloadBundle(url: string, bundleId: string): Promise<void> {
		await LiveUpdate.downloadBundle({
			url,
			bundleId,
		});
	}

	private async setBundle(bundleId: string): Promise<void> {
		await LiveUpdate.setBundle({ bundleId });

		await LiveUpdate.reload();
	}

	public async checkConditions(newVersion: string | undefined): Promise<void> {
		if (newVersion) {
			const appVersion = (await App.getInfo()).version;

			const splittedCurrentAppVersion = appVersion.split('.');

			const splittedNewVersion = newVersion.split('.');

			if (splittedCurrentAppVersion[0] < splittedNewVersion[0]) {
				await this.showConfirm();
				return;
			} else if (splittedCurrentAppVersion[0] === splittedNewVersion[0] && splittedCurrentAppVersion[1] < splittedNewVersion[1]) {
				await this.showConfirm();
				return;
			} else if (
				splittedCurrentAppVersion[0] === splittedNewVersion[0] &&
				splittedCurrentAppVersion[1] === splittedNewVersion[1] &&
				splittedCurrentAppVersion[2] < splittedNewVersion[2]
			) {
				await this.showConfirm();
				return;
			}
		}
	}

	public async showConfirm(): Promise<void> {
		const defaultConfigs = {
			header: 'NEW_VERSION_TITLE',
			acceptText: 'UPDATE',
		};

		const forceConfigs = {
			header: 'FORCE_NEW_VERSION_TITLE',
			backdropDismiss: false,
			buttons: [
				{
					text: this.translate.instant('UPDATE') as string,
					handler: () => this.openAppStoreEntry(),
				},
			],
		};

		await this.alertService.presentAlert(this.versionsData?.force ? forceConfigs : defaultConfigs, cb =>
			cb ? this.openAppStoreEntry() : '',
		);
	}

	public openAppStoreEntry(): void {
		if (this.plt.is('android')) {
			void openSite('https://play.google.com/store/apps/details?id=com.solar.limited&itscg=30200').then(() => {
				void Browser.close();
			});
		} else if (this.plt.is('ios')) {
			void openSite('https://apps.apple.com/ua/app/hey-beauty-master/id6467883609').then(() => {
				void Browser.close();
			});
		}
	}
}
