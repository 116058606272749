import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, ICollectionResponse } from '@solar/core/src';
import { MasterInfo } from '@solar/core/src/store';
import { UserRolesEnum } from '@solar/enums/user-roles.enum';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MastersService {
	constructor(
		private readonly http: HttpClient,
		private readonly authService: AuthService,
	) {}

	public add(body: MasterInfo): Observable<MasterInfo> {
		return this.http.post<MasterInfo>('/users', this.masterFormData(body));
	}

	public edit(body: MasterInfo): Observable<MasterInfo> {
		return this.http.patch<MasterInfo>(`users/${body.id}`, this.masterFormData(body));
	}

	public async deleteMaster(id: number): Promise<Object> {
		try {
			return await lastValueFrom(this.http.delete(`users/${id}`));
		} catch (e) {
			console.log(e);
		}
	}

	public deleteAvatar(): Observable<Object> {
		return this.http.delete(`users/avatar`);
	}

	public async getMaster(id: number): Promise<Object> {
		try {
			return await lastValueFrom(this.http.get(`users/${id}`));
		} catch (e) {
			console.log(e);
		}
	}

	public async getUserByUserName(user_name: string): Promise<Object> {
		try {
			return await lastValueFrom(this.http.get(`users/userByUserName/${user_name}`));
		} catch (e) {
			console.log(e);
		}
	}

	public async getUserByPhone(phone: string): Promise<Object> {
		try {
			return await lastValueFrom(this.http.get(`users/userByPhone/${phone}`));
		} catch (e) {
			console.log(e);
		}
	}

	public async getAllMasters(options?: {
		is_active?: boolean;
		random_sort?: boolean;
		category_id?: number[];
		ids?: string;
		role?: UserRolesEnum;
	}): Promise<ICollectionResponse<MasterInfo>> {
		try {
			return await lastValueFrom(
				this.http.get(`users`, { params: { role: options?.role ? options.role : UserRolesEnum.MASTER, ...options } }),
			);
		} catch (e) {
			console.log(e);
		}
	}

	public async getAllMastersWithTimeslots(options?: {
		is_active?: boolean;
		random_sort?: boolean;
		category_id?: number[];
		ids?: string;
		role?: UserRolesEnum;
		service_time?: string;
		country?: string;
		city?: string;
	}): Promise<ICollectionResponse<MasterInfo>> {
		try {
			const country =
				this.authService?.principal?.role !== UserRolesEnum.ADMIN && this.authService?.principal?.country
					? this.authService?.principal?.country
					: '';
			const city =
				this.authService?.principal?.role !== UserRolesEnum.ADMIN && this.authService?.principal?.city
					? this.authService?.principal?.city
					: '';
			return await lastValueFrom(
				this.http.get(`users/timeslots`, {
					params: {
						role: options?.role ? options.role : UserRolesEnum.MASTER,
						country,
						city,
						...options,
					},
				}),
			);
		} catch (e) {
			console.log(e);
		}
	}

	private masterFormData(body: Record<string, any>): FormData {
		const formData: FormData = new FormData();

		for (const prop in body) {
			if (body[prop] !== undefined) {
				if (prop === 'phone') {
					formData.append('phone', body['code'] + body['phone']);
				} else if (prop === 'managers' && Array.isArray(body[prop])) {
					formData.append('managers', JSON.stringify(body['managers']));
				} else {
					formData.append(prop, body[prop]);
				}
			}
		}

		return formData;
	}
}
