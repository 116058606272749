import { Component, inject, Input } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { CategoriesRes } from '@solar/core/src/store';
import { HeaderComponent } from '@solar/shared';
import { SubCategoriesFormComponent } from './sub-categories-form/sub-categories-form.component';

import { ISubCategoriesDto } from '@solar/core/src/services/rest/sub-categories/sub-categories.dto';

@Component({
	selector: 'hb-shop-category-edit',
	templateUrl: './shop-category-edit.component.html',
	styleUrls: ['./shop-category-edit.component.scss'],
	imports: [IonicModule, HeaderComponent, SubCategoriesFormComponent],
})
export class ShopCategoryEditComponent {
	@Input() public category: CategoriesRes;

	@Input() public editData: ISubCategoriesDto;

	public modal = inject(ModalController);
	constructor() {}
}
