export interface GeoLocationData {
	ip: string;
	city: string;
	region: string;
	country: string;
	loc: string;
	org: string;
	postal: string;
	timezone: string;
	readme: string;
}

export interface IUserLocation {
	city: string;
	country: string;
	latlng: string;
	postal_code: string;
	street: string;
}
