import { isDevMode } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

export const appUpdateChecker = (swUpdate: SwUpdate): (() => Promise<any>) => {
	return (): Promise<void> =>
		new Promise(async (resolve, reject) => {
			try {
				if (!isDevMode()) {
					const isNewVersion = await swUpdate.checkForUpdate();

					if (isNewVersion) {
						const isNewVersionActivated = await swUpdate.activateUpdate();

						if (isNewVersionActivated) {
							window.location.reload();
						}
					}
				}

				resolve();
			} catch (error) {
				console.error('Error checking or updating the app:', error);
				reject(error);
			}
		});
};
