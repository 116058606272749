@if (item?.message) {
	<ng-container *ngTemplateOutlet="main; context: { item: item }"></ng-container>
}
<ng-template #main let-item="item">
	<div
		[ngClass]="{
			'flex ion-align-items-end': true,
			'ion-margin-end ion-justify-content-end': item.user_info.id === myId,
			'ion-margin-start': item.user_info.id !== myId,
		}"
	>
		@if (item.user_info.id !== myId) {
			<hb-avatar class="m-r-6" [data]="item.user_info" size="small" [routerLink]="'/card/' + item.user_info.id + '/master'"></hb-avatar>
		}

		<ng-container *ngTemplateOutlet="messageBox; context: { item: item }"></ng-container>

		@if (item.user_info.id === myId) {
			<hb-avatar class="m-l-6" [data]="item.user_info" size="small" [routerLink]="'/card/' + item.user_info.id + '/master'"></hb-avatar>
		}
	</div>
	<div
		class="flex emojis-container"
		[ngClass]="{ 'ion-justify-content-end': item.user_info.id === myId, 'ion-justify-content-start': item.user_info.id !== myId }"
	>
		@if (item?.reactions?.length > 0) {
			@for (r of item.reactions; track $index) {
				<ion-button
					size="small"
					mode="ios"
					class="emoji-button"
					shape="round"
					(click)="onOpenAllReactions(r)"
					[ngClass]="{ primary: isMyReaction(r.users) }"
				>
					{{ r.reaction_type }}

					@if (r.users.length <= 2) {
						@for (u of r.users; track $index) {
							<hb-avatar
								[showOnlineDot]="false"
								[data]="{ id: u.user_id, user_name: u.user_name, img: u.user_img }"
								size="emoji-size"
								class="m-l-2"
							></hb-avatar>
						}
					} @else {
						{{ r.count }}
					}
				</ion-button>
			}
		}
	</div>
</ng-template>

<ng-template #messageBox let-item="item">
	<div #message class="flex ion-align-items-center">
		@if (item.user_info.id === myId) {
			<div>
				<ng-container *ngTemplateOutlet="actions; context: { item: item, class: 'm-r-6' }"></ng-container>
			</div>
		}

		<div
			[ngClass]="{
				'message-box m-b-10 ion-align-items-end line-height-normal': true,
				'primary right-radius': item.user_info.id === myId,
				'left-radius': item.user_info.id !== myId,
			}"
		>
			@if (item?.reply_to) {
				<div class="p-10 m-b-10 reply-to-box">
					<div class="font-weight-500">
						{{ this.getMessageById(item?.reply_to)?.user_info?.user_name }}
					</div>
					<div class="font-size-14">
						{{ this.getMessageById(item?.reply_to)?.message }}
					</div>
				</div>
			}

			<ng-container *ngTemplateOutlet="label; context: { item }"></ng-container>

			<ng-container *ngTemplateOutlet="dateTemp; context: { item }"></ng-container>
		</div>

		@if (
			(item.user_info.id !== myId && !messagesWsService.is_group()) ||
			(item.user_info.id !== myId && chatMembersState.authorizedMember()?.role === 'admin' && messagesWsService.is_group())
		) {
			<div>
				<ng-container *ngTemplateOutlet="actions; context: { item: item, class: 'm-l-6' }"></ng-container>
			</div>
		}
	</div>
</ng-template>

<ng-template #label let-item="item">
	<ion-label class="color-white sr-block relative z-index-1">
		<ng-container *ngTemplateOutlet="fileTemp; context: { files: item?.files }"></ng-container>

		<ng-container *ngTemplateOutlet="labelTemp; context: { item }"></ng-container>
	</ion-label>
</ng-template>

<ng-template #dateTemp let-item="item">
	<div class="flex ion-justify-content-end word-break-keep-all p-8">
		<ion-text class="font-size-10 color-white relative z-index-1">{{ item?.created_at | localizedDate: 'medium' }}</ion-text>
		@if (item.user_info.id === myId && !messagesWsService.is_group()) {
			<ion-icon class="m-l-4 relative z-index-1" name="checkmark-done-outline" [color]="item.is_read ? 'success' : 'light'"></ion-icon>
		}
	</div>
</ng-template>

<ng-template #actions let-item="item" let-class="class">
	@if (showActions) {
		<ion-buttons mode="ios" [ngClass]="class" class="flex flex-direction-column">
			<ion-button size="small" shape="round" class="action-btn" [id]="item?.id" (click)="openActions.emit(item)">
				<ion-icon class="font-size-12" color="medium" slot="icon-only" size="small" name="ellipsis-vertical"></ion-icon>
			</ion-button>

			<ion-button size="small" class="action-btn" shape="round" (click)="onEmojiClick(item)">
				<ion-icon class="font-size-12" color="medium" slot="icon-only" name="happy-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
	}
</ng-template>

<ng-template #fileTemp let-files="files">
	@if (files?.length > 0) {
		@for (file of files; track file) {
			<div id="img-{{ item?.id }}" class="img-container" (click)="sendOpenFile(true, file?.url, item?.id, item?.message, file?.fileName)">
				<img class="sr-block" [src]="file?.url" alt="" (load)="onImageLoad.emit()" />
			</div>
		}
	}
</ng-template>

<ng-template #labelTemp let-item="item">
	@if (messagesWsService.is_group()) {
		<div class="font-weight-500 font-size-14 flex-row ion-justify-content-between ion-align-items-center">
			<div>
				{{ item?.user_info?.user_name }}
			</div>

			<ion-text color="warning" class="font-size-10 ion-text-lowercase">
				{{ item.user_info.role === 'admin' ? ('ADMIN' | translate) : '' }}
			</ion-text>
		</div>
	}

	@defer (when item?.message && item?.message !== 'null') {
		<div class="font-size-14 p-t-8 p-l-8 p-r-8">
			@for (line of item.message.split('\n'); track line) {
				@for (part of formatMessage(line); track part) {
					@if (part.type === 'link') {
						<div (click)="navigate(part.url, part.queryParams, $event)">
							<ion-text color="warning" class="m-b-6 sr-block text-decoration-underline">{{ part.text }}</ion-text>
						</div>
					} @else if (part.content && part.content !== 'null') {
						<div>{{ part.content }}</div>
					}
				}
			}
		</div>
	}
</ng-template>
