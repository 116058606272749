export interface IReceipts {
	created_at: string;
	customer_id: number;
	id: number;
	master_id: number;
	metadata: {
		title: string;
		date: string;
		price: number;
		currency: string;
		qty: number;
		order_id: number;
		customer_id: number;
		master_id: number;
	}[];
	order_id: number;
	pdf_path: string;
	printer_id: number;
	store_id: string;
	total_amount: string;
	month?: string;
	year?: number;
}
