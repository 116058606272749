<div class="m-b-4 white-space-pre-wrap font-size-14">
	{{ translatedText ? translatedText : text }} <ng-content select="[afterText]"></ng-content>
</div>
@if (loading) {
	<ion-text class="font-weight-500" color="medium">
		<ion-spinner name="dots"></ion-spinner>
	</ion-text>
} @else {
	<ion-text class="font-weight-500 font-size-14" color="medium" (click)="translateText()">{{
		(translatedText ? 'SEE_ORIGINAL' : 'SEE_TRANSLATE') | translate
	}}</ion-text>
}
