import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CategoryServicesService } from '../../services/rest/category-services';
import { CategoryServicesRes } from '../../store';
import { FormEffects } from '../forms';
import { PagesEffects } from '../pages';
import { addServiceFormName, categoryServicesPageName, editServiceFormName, subCategoryServicesPageName } from './selectors';

@Injectable()
export class CategoryServicesEffects {
	public readonly $addService = this.formEffects.getSendFormEffect(addServiceFormName, (formState: { formData: CategoryServicesRes }) => {
		return this.categoryServices.addService(formState.formData);
	});

	public readonly $editService = this.formEffects.getSendFormEffect(editServiceFormName, (formState: { formData: CategoryServicesRes }) =>
		this.categoryServices.editService(formState.formData),
	);

	public readonly loadCategoriesServices$ = this.pagesEffects.getLoadPageEffect(
		categoryServicesPageName,
		(req: { reqData: { masterId: number; type?: string; categoryId?: number } }) =>
			this.categoryServices.getAll({ master_id: req.reqData.masterId, type: req.reqData.type, category_id: req.reqData.categoryId }).pipe(
				map(res => {
					return { data: res.data.map(it => ({ ...it, isChecked: false })) };
				}),
			),
	);

	public readonly loadSubCategoriesServices$ = this.pagesEffects.getLoadPageEffect(
		subCategoryServicesPageName,
		(req: { reqData: { masterId: number; type?: string; categoryId?: number } }) =>
			this.categoryServices.getAll({ master_id: req.reqData.masterId, type: req.reqData?.type, category_id: req.reqData?.categoryId }).pipe(
				map(res => {
					return { data: res.data.map(it => ({ ...it, isChecked: false })) };
				}),
			),
	);

	constructor(
		private readonly pagesEffects: PagesEffects,
		private readonly formEffects: FormEffects,
		private readonly categoryServices: CategoryServicesService,
	) {}
}
