<ion-header mode="ios">
	<hb-header [title]="editData?.title ? 'EDIT_CATEGORY' : 'ADD_CATEGORY'" [backUrl]="''">
		<ion-buttons slot="end">
			<ion-button (click)="modal.dismiss()">
				<ion-icon slot="end" name="close-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
	</hb-header>
</ion-header>

<hb-sub-categories-form [category]="category" [editData]="editData"></hb-sub-categories-form>
