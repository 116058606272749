import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { LoadingController, Platform } from '@ionic/angular';
import { ICollectionResponse } from '@solar/core/src';
import write_blob from 'capacitor-blob-writer';
import { formatISO } from 'date-fns';
import { lastValueFrom } from 'rxjs';
import { ToastService } from '../../toast/toast.service';
import { IReceipts } from './receipts.dto';
@Injectable({
	providedIn: 'root',
})
export class ReceiptsService {
	constructor(
		private readonly http: HttpClient,
		private readonly toastService: ToastService,
		private readonly loadingCtrl: LoadingController,
		private readonly platform: Platform,
	) {}

	public async getReceiptsAsync(params: {
		printer_id: number;
		page?: number;
		date_from?: string;
		date_to?: string;
	}): Promise<ICollectionResponse<IReceipts>> {
		return await lastValueFrom(this.http.get<ICollectionResponse<IReceipts>>(`/receipts`, { params }));
	}

	public async downloadMonthlyReceiptsAsync(params: { year: number; month?: string; printer_id?: number }): Promise<Blob> {
		const clientTime = formatISO(new Date());

		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		const response = await lastValueFrom(
			this.http.get('/receipts/download-path', {
				params: { ...params, clientTime, timezone },
				responseType: 'blob', // Получаем ответ как Blob (ZIP)
			}),
		);
		return response; // Вернем Blob, содержащий ZIP-файл
	}

	public async onDownloadMonthlyReceipts({
		year,
		month,
		printer_id,
	}: {
		year: number;
		month?: string;
		printer_id?: number;
	}): Promise<void> {
		await this.showLoading();

		// Получаем Blob с ZIP-архивом
		const zipBlob = await this.downloadMonthlyReceiptsAsync({ year, month, printer_id });

		if (!zipBlob) {
			await this.toastService.presentToast('Не удалось получить файлы для скачивания.');
			await this.loadingCtrl.dismiss();
			return;
		}

		// Формируем имя файла
		const fileName = month ? `receipts-${year}-${month.toString().padStart(2, '0')}.zip` : `receipts-${year}.zip`;

		const directory = this.platform.is('ios') ? Directory.Documents : Directory.Data;

		// Если платформа Web
		if (Capacitor.getPlatform() === 'web') {
			try {
				const objectUrl = URL.createObjectURL(zipBlob);
				const a = document.createElement('a');
				a.href = objectUrl;
				a.download = fileName; // Устанавливаем имя файла
				a.click();
				URL.revokeObjectURL(objectUrl); // Очищаем URL
			} catch (error) {
				console.error('Ошибка при скачивании ZIP:', error);
				await this.toastService.presentToast('Ошибка при скачивании ZIP.');
			}
		} else {
			try {
				// Для мобильных платформ сохраняем файл на устройстве
				const uri = await write_blob({
					path: 'heybeauty/' + fileName,
					directory,
					blob: zipBlob,
					recursive: true,
				});
				if (uri) {
					await FileOpener.openFile({
						path: uri,
					});
				}
			} catch (error) {
				console.error('Ошибка при сохранении ZIP:', error);
				await this.toastService.presentToast('Ошибка при сохранении ZIP: mobile');
			}
		}

		await this.loadingCtrl.dismiss();
	}

	public async downloadReceiptsAsync(params: {
		printer_id?: number;
		order_id: number;
		clientTime: string;
		timezone: string;
	}): Promise<Blob> {
		const response = await lastValueFrom(
			this.http.get('/receipts/download', {
				params,
				responseType: 'blob', // Получаем ответ как Blob (PDF)
			}),
		);
		return response; // Вернем Blob, который будет содержать PDF
	}

	public async onDownload({ order_id, printer_id }: { order_id: number; printer_id?: number }): Promise<void> {
		await this.showLoading();
		const clientTime = formatISO(new Date());

		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		// Получаем Blob с PDF
		const pdfBlob = await this.downloadReceiptsAsync({
			printer_id,
			order_id,
			clientTime,
			timezone,
		});

		if (!pdfBlob) {
			await this.toastService.presentToast('Не удалось получить файл для скачивания.');
			await this.loadingCtrl.dismiss();
			return;
		}

		const fileName = `receipt-${order_id}.pdf`; // Можно настроить имя файла динамически
		const directory = this.platform.is('ios') ? Directory.Documents : Directory.Data;

		// Если платформа Web
		if (Capacitor.getPlatform() === 'web') {
			try {
				const objectUrl = URL.createObjectURL(pdfBlob);
				const a = document.createElement('a');
				a.href = objectUrl;
				a.download = fileName; // Устанавливаем имя файла
				a.click();
				URL.revokeObjectURL(objectUrl); // Очищаем URL
			} catch (error) {
				console.error('Ошибка при скачивании файла:', error);
				await this.toastService.presentToast('Ошибка при скачивании PDF.');
			}
		} else {
			try {
				// Для мобильных платформ сохраняем файл на устройстве

				// await this.toastService.presentToast('PDF успешно сохранен в устройстве.');

				const uri = await write_blob({
					path: 'heybeauty/' + fileName + '.pdf',
					directory,
					blob: pdfBlob,
					recursive: true,
				});
				if (uri) {
					await FileOpener.openFile({
						path: uri,
					});
				}
			} catch (error) {
				console.error('Ошибка при сохранении PDF: mobile', error);
				await this.toastService.presentToast('Ошибка при сохранении PDF: mobile');
			}
		}

		await this.loadingCtrl.dismiss();
	}

	// Преобразование Blob в base64
	private async blobToBase64(blob: Blob): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result as string);
			reader.onerror = reject;
			reader.readAsDataURL(blob); // Читаем Blob как DataURL (base64)
		});
	}

	// Метод для отображения индикатора загрузки
	private async showLoading(): Promise<void> {
		const loading = await this.loadingCtrl.create({
			spinner: 'bubbles',
			translucent: true,
			duration: 10000,
		});

		await loading.present();
	}

	private async dismissLoading(): Promise<void> {
		await this.loadingCtrl.dismiss();
	}
}
