import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CategoriesService } from '../../services/rest';
import { FormEffects } from '../forms';
import { PagesEffects } from '../pages';
import {
	addCategoryFormName,
	categoriesPageName,
	categoryPageName,
	editCategoryFormName,
	loadOnlyCategories,
	userCategoriesPageName,
} from './selectors';

@Injectable()
export class CategoriesEffects {
	public readonly $addCategory = this.formEffects.getSendFormEffect(addCategoryFormName, formState => {
		return this.categoriesService.addCategory(formState.formData);
	});

	public readonly $editCategory = this.formEffects.getSendFormEffect(editCategoryFormName, formState => {
		return this.categoriesService.editCategory(formState.formData);
	});
	public readonly loadCategories$ = this.pagesEffects.getLoadPageEffect(categoriesPageName, req =>
		this.categoriesService.loadCategories(req.reqData).pipe(map(res => ({ data: res.data }))),
	);

	public readonly loadOnlyCategories$ = this.pagesEffects.getLoadPageEffect(loadOnlyCategories, req =>
		this.categoriesService.loadCategories(req.reqData).pipe(map(res => ({ data: res.data }))),
	);

	public readonly loadCategory$ = this.pagesEffects.getLoadPageEffect(categoryPageName, ({ reqData }) => {
		return this.categoriesService.loaCategory(reqData.id).pipe(map(res => ({ data: res })));
	});

	public readonly userCategories$ = this.pagesEffects.getLoadPageEffect(userCategoriesPageName, ({ reqData }) => {
		return this.categoriesService.loadCategories(reqData).pipe(map(res => ({ data: res.data })));
	});

	// loadPushOffers$ = this.pagesEffects.getLoadPagePushEffect(offersPageName, ({ page, filters }) =>
	//     this.offersService.getAll({ page, filters }).pipe(map(res => ({ ...res, data: res.items })))
	// );
	//
	// deleteOffer$ = this.formEffects.getSendFormEffect(deleteOffer, ({ id }) => {
	//     return this.offersService.delete(id);
	// });

	constructor(
		private readonly pagesEffects: PagesEffects,
		private readonly formEffects: FormEffects,
		private readonly categoriesService: CategoriesService,
	) {}
}
