import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { IAppState } from '@solar/core/src';
import { ClearPage, LoadPage, selectPageByName } from '@solar/core/src/store';
import { loadReviews } from '@solar/core/src/store/reviews';
import { StarsComponent } from '../../../components/statefull/stars/stars.component';
import { AvatarComponent } from '../../../components/stateless/avatar/avatar.component';
import { HeaderComponent } from '../../../components/stateless/header/header.component';
import { IsAuthorizedDirective } from '../../../directives';
import { DateAgoPipe, FullNamePipe } from '../../../pipes';
import { ReviewsModalComponent } from '../reviews-modal/reviews-modal.component';

@Component({
	selector: 'hb-reviews-list',
	templateUrl: './reviews-list.component.html',
	styleUrls: ['./reviews-list.component.scss'],
	imports: [
		AsyncPipe,
		IonicModule,
		ReviewsModalComponent,
		TranslateModule,
		DateAgoPipe,
		AvatarComponent,
		IsAuthorizedDirective,
		StarsComponent,
		FullNamePipe,
		HeaderComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsListComponent implements OnInit, OnDestroy {
	@Input() public userId: number;

	public reviews$ = this.store.select(selectPageByName(loadReviews));

	protected readonly route = inject(ActivatedRoute);

	constructor(
		private readonly store: Store<IAppState>,
		private readonly router: Router,
	) {}

	public ngOnInit(): void {
		this.userId = +this.route.snapshot.paramMap.get('id');
		this.store.dispatch(LoadPage({ path: loadReviews, reqData: { user_id: this.userId } }));
	}

	protected onRouteToLogin(): void {
		void this.router.navigate(['/login']);
	}

	public ngOnDestroy(): void {
		this.store.dispatch(ClearPage({ path: loadReviews }));
	}
}
