@if (subCategoriesStateService.items()?.length) {
	<div [formGroup]="form">
		<ion-select
			[ngClass]="{ 'ion-margin-bottom': mode === 'md' }"
			[mode]="mode"
			label="{{ 'CATEGORIES' | translate }}"
			label-placement="fixed"
			fill="outline"
			[formControlName]="controlName"
			[multiple]="multiple"
			[compareWith]="compareWith"
		>
			@for (c of subCategoriesStateService.items(); track $index) {
				<ion-select-option [value]="c.id">{{ c | serverTranslate | titlecase }}</ion-select-option>
			}
		</ion-select>
	</div>
} @else {
	<ion-item>
		<ion-button id="open-add-cat-dialog" class="ion-no-margin" mode="ios" fill="outline">
			<ion-icon name="add-outline"></ion-icon> {{ 'ADD_CATEGORY' | translate }}
		</ion-button>
	</ion-item>

	<ion-modal #modal trigger="open-add-cat-dialog">
		<ng-template>
			<hb-shop-category-edit [category]="parentData"></hb-shop-category-edit>
		</ng-template>
	</ion-modal>
}
