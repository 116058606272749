import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { AlertController } from '@ionic/angular';
import { IAddresses, timeoutUtil } from '@solar/core/src';
import { lastValueFrom, Observable } from 'rxjs';
import { GeoLocationData, IUserLocation } from './geolocation.model';

@Injectable({
	providedIn: 'root',
})
export class GeoLocationService {
	public userLocation: WritableSignal<IUserLocation> = signal(null);

	private readonly apiUrl = 'https://ipinfo.io/json?token=302000f4593126';

	constructor(
		private readonly http: HttpClient,
		private readonly alertCtrl: AlertController,
	) {}

	public getGeoLocationData(): Observable<GeoLocationData> {
		return this.http.get<GeoLocationData>(this.apiUrl);
	}

	public async getCurrentPosition(timer = 5000): Promise<IUserLocation> {
		try {
			await timeoutUtil(timer);
			const coordinates = await Geolocation.getCurrentPosition();
			const { latitude, longitude } = coordinates.coords;
			const userLocation = await this.getAddressFromCoordinatesAsync(latitude, longitude);

			this.userLocation.set(userLocation);

			return Promise.resolve(userLocation);
		} catch (error) {
			if (error?.message?.includes('User denied Geolocation')) {
				const alert = await this.alertCtrl.create({
					header: 'Location access was denied. Please in the setting to enable location services and try again.',
				});

				void alert.present();
			}
		}
	}

	public getAddressFromCoordinates(lat, lon): Observable<IAddresses> {
		return this.http.get<IAddresses>(`/geolocation/${lat},${lon}`);
	}

	public async getAddressFromCoordinatesAsync(lat, lon): Promise<IAddresses> {
		return await lastValueFrom(this.getAddressFromCoordinates(lat, lon));
	}
}
