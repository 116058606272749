export interface ISubCategoriesDto {
	id?: number;
	title: string;
	parent_id: number;
	img: string | null | File;
	description: string;
	position?: number;
}

export interface ISubCategoriesModel {
	title: string;
	parent_id: number;
	img?: File | null;
	description: string;
	position?: number;
}
