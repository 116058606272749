import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { ICollectionResponse } from '@solar/core/src';
import { lastValueFrom } from 'rxjs';
import { IMessage } from '../../../interfaces';

@Injectable({
	providedIn: 'root',
})
export class UnreadMessagesStateService {
	public messages: WritableSignal<ICollectionResponse<IMessage>> = signal({ data: [] });

	constructor(private readonly http: HttpClient) {}

	/**
	 * Loads messages data for a specific chat and page, and updates the messages state.
	 * @param params - An object containing chat_id and page number.
	 */
	public async load(): Promise<void> {
		const data = await this.fetchData();
		this.messages.set(data);
	}

	/**
	 * Fetches unread messages for the current principal user.
	 * @returns A promise that resolves to a collection of unread messages.
	 */
	public async fetchData(): Promise<ICollectionResponse<IMessage>> {
		// Retrieve the principal user information from local storage
		const principal: { id: string } = JSON.parse(localStorage.getItem('principal'));

		if (!principal?.id) {
			return Promise.resolve({ data: [] });
		}

		return lastValueFrom(this.http.get<ICollectionResponse<IMessage>>(`messages-unread?receiver=${principal.id}`));
	}
}
